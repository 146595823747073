import {
  type ClientLoaderFunctionArgs,
  Link,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { type ReactNode, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useTitle } from 'react-use';
import useSWR from 'swr';

import {
  type DtoHubSpotDeal,
  type DtoHubSpotDealAssociation,
  type DtoProduct,
  type DtoSimpleStripePrice,
  type EnumsProductBillingInterval,
  type HubspotLineItem,
  StripePriceRecurringInterval,
  StripePriceType,
} from '@lp-lib/api-service-client/public';

import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { NewWindowIcon } from '../components/icons/NewWindowIcon';
import { Loading } from '../components/Loading';
import { ProductUtils } from '../components/Product/utils';
import { useInstance } from '../hooks/useInstance';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { err2s, makeTitle } from '../utils/common';
import { buildReactSelectStyles } from '../utils/react-select';

const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });
  const resp = await apiService.hubSpotDeal.getDeal(id);
  const resp2 = await apiService.product.getPublicProducts();

  return { ...resp.data, products: resp2.data.marketed };
}

function DealBasicInfo(props: { deal: DtoHubSpotDeal }) {
  const { deal } = props;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const closeDate = deal.closeDate
    ? utcToZonedTime(deal.closeDate, timezone)
    : null;
  const owner = deal.owner
    ? `${deal.owner.firstName} ${deal.owner.lastName}`
    : deal.ownerId;

  const stage = deal.pipeline?.stages.find(
    (s) => s.id === deal.pipelineStageId
  );

  return (
    <div className='w-full border border-secondary rounded-lg p-2'>
      <header className='font-bold mb-2 text-base'>Basic Info</header>
      <div className='flex items-center gap-1'>
        <label className='font-bold'>Amount:</label>
        <p>{currency.format(Number(deal.amount))}</p>
      </div>
      <div className='flex items-center gap-1'>
        <label className='font-bold'>Close Date:</label>
        <p>{closeDate ? format(closeDate, 'MMM d, yyyy h:mm aa O') : 'N/A'}</p>
      </div>
      <div className='flex items-center gap-1'>
        <label className='font-bold'>Stage:</label>
        <p>
          {stage?.label ?? 'N/A'} ({deal.pipeline?.label ?? 'N/A'})
        </p>
      </div>
      <div className='flex items-center gap-1'>
        <label className='font-bold'>Owner:</label>
        <p>{owner}</p>
      </div>
    </div>
  );
}

function DealContacts(props: {
  contacts: DtoHubSpotDealAssociation['contacts'];
}) {
  const { contacts } = props;
  return (
    <div className='w-full border border-secondary rounded-lg p-2'>
      <header className='font-bold mb-2 text-base'>
        Contacts ({contacts.length})
      </header>
      <div className='flex flex-col gap-1'>
        {contacts.map((contact) => (
          <div key={contact.id}>
            {contact.firstname} {contact.lastname} ({contact.email})
          </div>
        ))}
      </div>
    </div>
  );
}

function DealLineItems(props: {
  lineItems: DtoHubSpotDealAssociation['lineItems'];
}) {
  const { lineItems } = props;
  return (
    <div className='w-full border border-secondary rounded-lg p-2'>
      <header className='font-bold mb-2 text-base'>
        Line Items ({lineItems.length})
      </header>
      <div className='flex flex-col gap-1'>
        {lineItems.map((lineItem) => (
          <div key={lineItem.hs_object_id} className='flex flex-col gap-0.5'>
            <div className='flex items-center gap-1'>
              <p>{lineItem.name}</p>
              <p className='text-xs text-icon-gray'>x{lineItem.quantity}</p>
              <p className='ml-auto'>
                {currency.format(Number(lineItem.amount))}
              </p>
            </div>
            <div className='text-icon-gray text-sms'>
              URL: {lineItem.hs_url || '(Not Set)'}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

type PriceRecurring =
  | {
      custom: false;
      interval: EnumsProductBillingInterval;
    }
  | {
      custom: true;
      interval: StripePriceRecurringInterval;
      interval_count: number;
    };

type PriceOption = {
  id: string;
  amount: number;
  description: string;
  type: StripePriceType;
} & PriceRecurring;

function FormatPriceOptionLabel(props: { option: PriceOption }) {
  const { option } = props;
  if (!option.custom) {
    return (
      <div className='flex items-center justify-between'>
        <div>
          {currency.format(option.amount)} USD /
          {ProductUtils.FormatInterval(option.interval, true)}
        </div>
        <div>{option.description}</div>
      </div>
    );
  }

  if (option.type === StripePriceType.PriceTypeOneTime) {
    return (
      <div className='flex items-center justify-between'>
        <div>{currency.format(option.amount)} USD / One-time</div>
        <div>{option.description}</div>
      </div>
    );
  } else {
    const interval =
      option.interval_count > 1
        ? `Every ${option.interval_count} ${option.interval}s`
        : `Per ${option.interval}`;
    return (
      <div className='flex items-center justify-between'>
        <div>
          {currency.format(option.amount)} USD / {interval}
        </div>
        <div>{option.description}</div>
      </div>
    );
  }
}

function usePriceOptions(product: DtoProduct | undefined) {
  const productId = product?.id;
  const {
    data: stripePrices,
    isLoading,
    mutate,
  } = useSWR(
    productId ? `/products/${productId}/stripe-prices` : null,
    async () => {
      if (!productId) return;
      const resp = await apiService.product.listStripePrices(productId);
      return resp.data.prices;
    }
  );

  const priceOptions = useMemo(() => {
    if (!product) return [];
    const managedPrices = (product.prices ?? []).sort(
      (a, b) => a.amount - b.amount
    );
    const managedPriceOptions: PriceOption[] = [];
    const set = new Set<string>();
    for (const p of managedPrices) {
      if (!p.stripePriceId || p.archived) continue;
      set.add(p.stripePriceId);
      managedPriceOptions.push({
        id: p.stripePriceId,
        amount: p.amount,
        description: `Max Seats: ${p.maxSeats}`,
        type: StripePriceType.PriceTypeRecurring,
        custom: false,
        interval: p.billingInterval,
      });
    }
    const customPrices = (stripePrices ?? [])
      .filter((p) => !set.has(p.id))
      .sort((a, b) => a.unit_amount - b.unit_amount);
    const customPriceOptions: PriceOption[] = [];
    for (const p of customPrices) {
      if (!p.active) continue;
      customPriceOptions.push({
        id: p.id,
        amount: p.unit_amount / 100,
        description: p.description,
        type: p.type,
        custom: true,
        interval: p.recurring?.interval,
        interval_count: p.recurring?.interval_count ?? 0,
      });
    }
    return [
      { label: 'Custom (Unmanaged)', options: customPriceOptions },
      { label: 'Managed', options: managedPriceOptions },
    ];
  }, [product, stripePrices]);

  return { priceOptions, isLoading, mutate };
}

function PriceTypeCard(props: {
  title: string;
  description: string;
  selected: boolean;
  onSelect: () => void;
  children?: React.ReactNode;
}) {
  return (
    <label className='w-full p-4 border border-secondary rounded-xl flex items-start gap-4 hover:cursor-pointer'>
      <input
        type={'radio'}
        checked={props.selected}
        className='field-radio w-4 h-4'
        onChange={(e) => {
          e.stopPropagation();
          props.onSelect();
        }}
      />

      <div className='flex-1'>
        <h3 className='text-sms font-bold'>{props.title}</h3>
        <p className='text-sms text-icon-gray'>{props.description}</p>
        {props.children}
      </div>
    </label>
  );
}

type FormData = {
  type: StripePriceType;
  recurring?: {
    interval: StripePriceRecurringInterval;
    interval_count: number;
  };
  description: string;
  amount: number;
};

function PricePreviewModal(props: { data: FormData }) {
  const { data } = props;
  return (
    <div className='px-5 py-3 text-white text-center'>
      <p className='text-2xl font-medium'>Review the price</p>
      <table className='w-full mt-4 text-sms text-left border border-secondary'>
        <tbody>
          <tr className='odd:bg-lp-gray-001'>
            <td className='font-medium p-1'>Type</td>
            <td className='p-1'>
              {data.type === StripePriceType.PriceTypeRecurring
                ? 'Recurring'
                : 'One-time'}
            </td>
          </tr>
          <tr className='odd:bg-lp-gray-001'>
            <td className='font-medium p-1'>Amount</td>
            <td className='p-1'>{currency.format(data.amount)}</td>
          </tr>
          {data.type === StripePriceType.PriceTypeRecurring &&
            data.recurring && (
              <tr className='odd:bg-lp-gray-001'>
                <td className='font-medium p-1'>Billing Period</td>
                <td className='p-1'>
                  {`Every ${data.recurring.interval_count} ${data.recurring.interval}(s)`}
                </td>
              </tr>
            )}
          <tr className='odd:bg-lp-gray-001'>
            <td className='font-medium p-1'>Description</td>
            <td className='p-1'>{data.description || 'N/A'}</td>
          </tr>
        </tbody>
      </table>
      <p className='mt-4 text-sms'>
        We don't support editing prices after they are created. Please make sure
        the price is correct before saving.
      </p>
    </div>
  );
}

function CustomPriceEditor(props: {
  product: DtoProduct;
  lineItem: HubspotLineItem;
  onCancel: () => void;
  onSave: (price: DtoSimpleStripePrice) => Promise<void>;
}) {
  const { product, lineItem } = props;
  const form = useForm<FormData>({
    defaultValues: {
      type: StripePriceType.PriceTypeRecurring,
      recurring: {
        interval: StripePriceRecurringInterval.PriceRecurringIntervalMonth,
        interval_count: 3,
      },
      amount: Number(lineItem.amount),
      description: '',
    },
  });
  const styles = useMemo(
    () =>
      buildReactSelectStyles({
        override: {
          control: {
            height: '40px',
          },
        },
      }),
    []
  );
  const intervalOptions = useInstance(() => [
    {
      label: StripePriceRecurringInterval.PriceRecurringIntervalDay,
      value: StripePriceRecurringInterval.PriceRecurringIntervalDay,
    },
    {
      label: StripePriceRecurringInterval.PriceRecurringIntervalWeek,
      value: StripePriceRecurringInterval.PriceRecurringIntervalWeek,
    },
    {
      label: StripePriceRecurringInterval.PriceRecurringIntervalMonth,
      value: StripePriceRecurringInterval.PriceRecurringIntervalMonth,
    },
    {
      label: StripePriceRecurringInterval.PriceRecurringIntervalYear,
      value: StripePriceRecurringInterval.PriceRecurringIntervalYear,
    },
  ]);

  const type = form.watch('type');
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
  } = form;

  const onSave = handleSubmit(async (data) => {
    const resp = await triggerModal({
      kind: 'confirm-cancel',
      prompt: <PricePreviewModal data={data} />,
      confirmBtnLabel: 'Continue',
      cancelBtnLabel: 'Cancel',
    });
    if (resp.result === 'canceled') return;
    try {
      const resp = await apiService.product.addUnmangedStripePrice(product.id, {
        amount: data.amount,
        description: data.description,
        type: data.type,
        recurring: data.recurring,
      });
      await props.onSave(resp.data.price);
    } catch (error) {
      form.setError('root.serverError', {
        message: err2s(error) ?? 'Something went wrong, please try again.',
      });
      throw error;
    }
  });

  return (
    <FormProvider {...form}>
      <section className='p-7.5 w-full h-full relative'>
        <header className='text-2xl font-medium'>{`Add price to ${product.name}`}</header>
        <main className='mt-5 w-full flex flex-col gap-5'>
          <Controller
            name='type'
            control={form.control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <div className='flex items-center gap-2'>
                <PriceTypeCard
                  title='Recurring'
                  description='Charge an ongoing fee'
                  selected={value === StripePriceType.PriceTypeRecurring}
                  onSelect={() => onChange(StripePriceType.PriceTypeRecurring)}
                />
                <PriceTypeCard
                  title='One-off'
                  description='Charge a one-time fee'
                  selected={value === StripePriceType.PriceTypeOneTime}
                  onSelect={() => onChange(StripePriceType.PriceTypeOneTime)}
                />
              </div>
            )}
          />
          <Controller
            name='amount'
            control={form.control}
            rules={{ required: true, min: 1 }}
            render={({ field: { value, onChange }, fieldState }) => (
              <div>
                <label className='font-bold mb-2'>Price Amount</label>
                <div className='flex items-center relative'>
                  <div className='absolute left-2'>$</div>
                  <input
                    type='number'
                    className={`${
                      fieldState.error ? 'field-error' : 'field'
                    } mb-0 h-10 px-8`}
                    placeholder='0.00'
                    value={value}
                    min={1}
                    onChange={(e) => onChange(Number(e.target.value))}
                  />
                  <div className='absolute right-2'>USD</div>
                </div>
              </div>
            )}
          />
          {type === StripePriceType.PriceTypeRecurring && (
            <Controller
              name='recurring'
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div>
                  <label className='font-bold mb-2'>Billing period</label>
                  <div className='flex items-center gap-1'>
                    <div className='text-sms text-secondary'>Every</div>
                    <input
                      type='number'
                      className={`${
                        fieldState.error ? 'field-error' : 'field'
                      } mb-0 h-10`}
                      placeholder='2'
                      value={value?.interval_count ?? 2}
                      min={1}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          interval_count: Number(e.target.value),
                        })
                      }
                    />
                    <Select
                      options={intervalOptions}
                      onChange={(o) =>
                        onChange({
                          ...value,
                          interval:
                            o?.value ??
                            StripePriceRecurringInterval.PriceRecurringIntervalMonth,
                        })
                      }
                      value={intervalOptions.find(
                        (o) => o.value === value?.interval
                      )}
                      classNamePrefix='select-box-v2'
                      className='w-full h-full'
                      styles={styles}
                    />
                  </div>
                </div>
              )}
            />
          )}
          <Controller
            name='description'
            control={form.control}
            render={({ field: { value, onChange }, fieldState }) => (
              <div>
                <label className='font-bold mb-2'>Price description</label>
                <div className='flex items-center relative'>
                  <input
                    type='text'
                    className={`${
                      fieldState.error ? 'field-error' : 'field'
                    } mb-0 h-10`}
                    placeholder='Use to organize your prices. Not shown to customers.'
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                </div>
              </div>
            )}
          />
          {errors.root?.serverError && (
            <div className='text-sms text-red-002'>
              {errors.root.serverError.message}
            </div>
          )}
        </main>
        <footer className='w-full absolute bottom-2 right-4 flex justify-end items-center gap-10'>
          <button type='button' className='btn' onClick={props.onCancel}>
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary h-10 px-4 min-w-30 flex items-center justify-center gap-1'
            onClick={onSave}
            disabled={isSubmitting}
          >
            {isSubmitting && <Loading text='' />}
            <p>Save</p>
          </button>
        </footer>
      </section>
    </FormProvider>
  );
}

function CustomizeDeal(props: {
  deal: DtoHubSpotDeal;
  association: DtoHubSpotDealAssociation;
  lineItem: HubspotLineItem;
  products: DtoProduct[];
  setSidePanel: (panel: ReactNode) => void;
}) {
  const { deal, lineItem, products } = props;
  const form = useForm<{
    productId?: string | null;
    stripePriceId?: string | null;
  }>({
    defaultValues: {
      productId: lineItem.hs_sku?.split(',')[0] || null,
      stripePriceId: lineItem.hs_sku?.split(',')[1] || null,
    },
  });
  const styles = useMemo(
    () =>
      buildReactSelectStyles({
        override: {
          control: {
            height: '40px',
          },
          groupHeading: {
            fontSize: '0.75rem',
            fontWeight: 600,
            color: '#8B9294',
            textTransform: 'uppercase',
            letterSpacing: '0.05rem',
          },
        },
      }),
    []
  );
  const productId = form.watch('productId');
  const product = products.find((p) => p.id === productId);
  const { priceOptions, isLoading, mutate } = usePriceOptions(product);

  const openAddCustomPriceEditor = () => {
    if (!product) return;
    props.setSidePanel(
      <CustomPriceEditor
        product={product}
        lineItem={lineItem}
        onCancel={() => props.setSidePanel(null)}
        onSave={async (price) => {
          await mutate();
          form.setValue('stripePriceId', price.id);
          props.setSidePanel(null);
        }}
      />
    );
  };

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = form;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const nagivate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    if (!data.productId || !data.stripePriceId) return;
    try {
      const resp = await apiService.hubSpotDeal.updateDealCustomPrice(deal.id, {
        productId: data.productId,
        stripePriceId: data.stripePriceId,
        lineItemId: lineItem.hs_object_id,
      });
      await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='p-3 text-white text-center'>
            <p className='text-2xl font-medium'>Price attached</p>
            <p className='mt-4 text-sm'>
              This is the{' '}
              <Link
                to={resp.data.paymentLinkUrl}
                target='_blank'
                className='underline text-primary'
              >
                payment link
              </Link>
              {'.'}
              <br />
              You can also check the latest{' '}
              <Link
                to={deal.link}
                target='_blank'
                className='underline text-primary'
              >
                deal
              </Link>{' '}
              on HubSpot.
            </p>
          </div>
        ),
        confirmBtnLabel: 'Okay',
        confirmOnly: true,
      });
      nagivate(0);
    } catch (error) {
      form.setError('root.serverError', {
        message: err2s(error) ?? 'Something went wrong, please try again.',
      });
      throw error;
    }
  });

  return (
    <form className='flex flex-col gap-4'>
      <header className='text-2xl font-medium'>Customize Deal Price</header>
      <main className='w-1/3 flex flex-col gap-4'>
        <Controller
          name='productId'
          control={form.control}
          rules={{ required: 'Please select the product' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className='w-full flex flex-col gap-1'>
              <label className='font-bold'>Product</label>
              <Select<DtoProduct>
                options={products}
                onChange={(o) => onChange(o?.id)}
                value={products.find((p) => p.id === value) ?? null}
                classNamePrefix='select-box-v2'
                className='w-full h-full'
                styles={styles}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
              {error && (
                <div className='text-sms text-red-002'>{error.message}</div>
              )}
            </div>
          )}
        />
        <Controller
          name='stripePriceId'
          control={form.control}
          rules={{ required: 'Please select the price' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <div className='w-full flex flex-col gap-1'>
                <div className='flex items-center justify-between'>
                  <label className='font-bold'>Price</label>
                  {productId && !isLoading && (
                    <button
                      type='button'
                      className='text-sms text-left text-primary underline'
                      onClick={openAddCustomPriceEditor}
                    >
                      Add new custom price
                    </button>
                  )}
                </div>
                {isLoading ? (
                  <Loading text='' />
                ) : (
                  <Select<PriceOption>
                    isDisabled={!productId}
                    options={priceOptions}
                    onChange={(o) => onChange(o?.id)}
                    value={
                      priceOptions
                        .flatMap((o) => o.options)
                        .find((o) => o.id === value) ?? null
                    }
                    classNamePrefix='select-box-v2'
                    className='w-full h-full'
                    styles={styles}
                    getOptionValue={(option) => option.id}
                    formatOptionLabel={(option) => (
                      <FormatPriceOptionLabel option={option} />
                    )}
                  />
                )}
                {error && (
                  <div className='text-sms text-red-002'>{error.message}</div>
                )}
              </div>
            );
          }}
        />
      </main>
      <footer className='flex items-center gap-4 mt-4'>
        <button
          type='button'
          className='btn-secondary w-30 h-10'
          disabled={!isDirty}
          onClick={() => reset()}
        >
          Reset
        </button>
        <button
          type='button'
          className='btn-primary w-60 h-10 flex items-center justify-center gap-1'
          onClick={onSubmit}
          disabled={!isDirty || isSubmitting}
        >
          {isSubmitting && <Loading text='' />}
          <p>Attach Price to Deal</p>
        </button>
      </footer>
    </form>
  );
}

export function Component() {
  const { deal, association, products } = useLoaderData<typeof clientLoader>();
  useTitle(makeTitle(deal.name));

  const [sidePanel, setSidePanel] = useState<ReactNode>(null);
  const customLineItem = association.lineItems.find((li) => !li.hs_product_id);
  const eligibleForCustomization =
    association.lineItems.length === 1 && customLineItem;

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <div className='w-full relative text-white flex flex-col gap-10'>
        <header className='flex justify-between items-center'>
          <h1 className='font-bold text-3xl flex items-center gap-1'>
            <p>{deal.name}</p>
            <Link to={deal.link} target='_blank'>
              <NewWindowIcon className='w-6 h-6 fill-current' />
            </Link>
          </h1>
        </header>
        <div className='grid grid-cols-3 gap-4 text-sm'>
          <DealBasicInfo deal={deal} />
          <DealContacts contacts={association.contacts} />
          <DealLineItems lineItems={association.lineItems} />
        </div>
        {!eligibleForCustomization ? (
          <div className='text-red-002'>
            This deal is not eligible for customization, we only support deals
            with a single custom line item that is not associate with any
            HubSpot product. Please review your deal configuration.
          </div>
        ) : (
          <CustomizeDeal
            deal={deal}
            association={association}
            products={products}
            lineItem={customLineItem}
            setSidePanel={setSidePanel}
          />
        )}
        {sidePanel && (
          <div className='fixed inset-0 z-50'>
            <div className='absolute inset-0 bg-lp-black-001' />
            <div className='absolute top-0 bottom-0 right-0 w-1/2 bg-layer-001'>
              {sidePanel}
            </div>
          </div>
        )}
      </div>
    </AdminView>
  );
}
